import React, { useEffect, useState } from "react";

const App = () => {
  const [data, setData] = useState();
  useEffect(() => {}, []);
  useEffect(() => {
    fetch("https://admin.kavishala.in/news_park/home/")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: data?.content,
      }}
    ></div>
  );
};

export default App;
